import { IAdHocProject, IHatchetAiCompanyProjectOcr, IProject } from "../../models/applicationState";
import { AnyAction } from "../actions/actionCreators";
import { ActionTypes } from "../actions/actionTypes";

export const reducer = (state: IAdHocProject = null, action: AnyAction): IAdHocProject => {
    switch(action.type) {
        case ActionTypes.AD_HOC_OCR_V2_LOAD_PROJECT: {
            let project: IProject = JSON.parse(action.payload.project);
            let ocrs = [...action.payload.ocrs];            
            let labels = [...action.payload.labels]
            let assetUrls: string[] = action.payload.assetUrls;
            return { 
                ...state,
                project: project,
                ocrs: ocrs, 
                assetUrls: assetUrls,
                source: action.payload.source, 
                labels: labels,
                isV2: true,
                hasModel: action.payload.hasModel
            };
            break;
        }
        default: {
            return state;
        }
    }
}