import { Dispatch } from "redux";
import { IAutoLabelModelMetrics, IAutoLabelModelMetricsOptions, IAutoLabelModelMetricsResultModel, IAutoLabelingMetricsResultModel, IFormRecognizerModel, IMetricsState, IMetricsV2Result, IOcrMetricsResultModel } from "../../models/applicationState";
import MetricsService from "../../services/metricService";
import { createPayloadAction, IPayloadAction } from "./actionCreators";
import { ActionTypes } from "./actionTypes";

export default interface IMetricsActions {
    getAutoLabelModelMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<void>;
    getAutoLabelingMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<void>;
    getAutoLabelModelMetricsOptions(): Promise<void>;
    getOcrMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<void>;
    getFormRecognizerModels(token: string): (dispatch: Dispatch) => Promise<void>;
    deleteFormRecognizerModel(modelId: string): (dispatch: Dispatch) => Promise<boolean>;
    getMetricsV2(page: number, itemsPerPage: number, source: string, processor: string, clientId: string, vendor, startDate: Date, endDate: Date, token: string, isInit: boolean): Promise<void>;
}

//AutoLabelModelMetrics
export function getAutoLabelModelMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        const metricsService = new MetricsService();
        const metrics = await metricsService.getAutoLabelModelMetrics(page, itemsPerPage, processor, clientId, startDate, endDate, token);
        dispatch(getAutoLabelModelMetricsAction(metrics));
    }
}
export interface IGetAutoLabelModelMetricsAction extends IPayloadAction<string, IAutoLabelModelMetricsResultModel> { 
    type: ActionTypes.GET_AUTOLABEL_METRICS;
}
export const getAutoLabelModelMetricsAction = createPayloadAction<IGetAutoLabelModelMetricsAction>(ActionTypes.GET_AUTOLABEL_METRICS);

//AutoLabeling Metrics
export function getAutoLabelingMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        const metricsService = new MetricsService();
        const metrics = await metricsService.getAutoLabelingMetrics(page, itemsPerPage, processor, clientId, startDate, endDate, token);
        dispatch(getAutoLabelingMetricsAction(metrics));
    }
}
export interface IGetAutoLabelingMetricsAction extends IPayloadAction<string, IAutoLabelingMetricsResultModel> { 
    type: ActionTypes.GET_AUTOLABELING_METRICS;
}
export const getAutoLabelingMetricsAction = createPayloadAction<IGetAutoLabelingMetricsAction>(ActionTypes.GET_AUTOLABELING_METRICS);

//Ocr Metrics
export function getOcrMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        const metricsService = new MetricsService();
        const metrics = await metricsService.getOcrMetrics(page, itemsPerPage, processor, clientId, startDate, endDate, token);
        dispatch(getOcrMetricsAction(metrics));
    }
}
export interface IGetOcrMetricsAction extends IPayloadAction<string, IOcrMetricsResultModel> { 
    type: ActionTypes.GET_OCR_METRICS;
}
export const getOcrMetricsAction = createPayloadAction<IGetOcrMetricsAction>(ActionTypes.GET_OCR_METRICS);

//Form Recognizer Models
export function getFormRecognizerModels(token: string): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        const metricsService = new MetricsService();
        const frModels = await metricsService.getFormRecognizerModels(token);
        dispatch(getFormRecognizerModelsAction(frModels.value));
    }
}
export interface IGetFormRecognizerModelsAction extends IPayloadAction<string, IFormRecognizerModel[]> { 
    type: ActionTypes.GET_FORM_RECOGNIZER_MODELS;
}
export const getFormRecognizerModelsAction = createPayloadAction<IGetFormRecognizerModelsAction>(ActionTypes.GET_FORM_RECOGNIZER_MODELS);

//Form Recognizer Delete Model
export function deleteFormRecognizerModel(modelId: string): (dispatch: Dispatch) => Promise<boolean> {
    return async (dispatch: Dispatch) => {
        const metricsService = new MetricsService();
        const frModels = await metricsService.deleteFormRecognizerModel(modelId);
        dispatch(deleteFormRecognizerModelAction(modelId));
        return true;
    }
}
export interface IDeleteFormRecognizerModelAction extends IPayloadAction<string, string> { 
    type: ActionTypes.DELETE_FORM_RECOGNIZER_MODEL;
}
export const deleteFormRecognizerModelAction = createPayloadAction<IDeleteFormRecognizerModelAction>(ActionTypes.DELETE_FORM_RECOGNIZER_MODEL);


//AutoLabelModelMetricsOptions
export function getAutoLabelModelMetricsOptions(): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        const metricsService = new MetricsService();
        const metricsOptions = await metricsService.getAutoLabelModelMetricsOptions();
        dispatch(getAutoLabelModelMetricsOptionsAction(metricsOptions));
    }
}
export interface IGetAutoLabelModelMetricsOptionsAction extends IPayloadAction<string, IAutoLabelModelMetricsOptions> { 
    type: ActionTypes.GET_AUTOLABEL_METRICS_OPTIONS;
}
export const getAutoLabelModelMetricsOptionsAction = createPayloadAction<IGetAutoLabelModelMetricsOptionsAction>(ActionTypes.GET_AUTOLABEL_METRICS_OPTIONS);

//Metrics V2
export function getMetricsV2(page: number, itemsPerPage: number, source: string, processor: string, clientId: string, vendor, startDate: Date, endDate: Date, token: string, isInit: boolean): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        const metricsService = new MetricsService();
        let metrics = await metricsService.getMetricsV2(page, itemsPerPage, processor, source, clientId, vendor, startDate, endDate, token);
        if (isInit) {
            const metricsOptions = await metricsService.getMetricsV2Options(token);
            if (metrics && metricsOptions) {
                metrics = {
                    ...metrics, 
                    processors: metricsOptions.processors, 
                    sources: metricsOptions.sources,
                    clientIds: metricsOptions.clientIds
                }
            }
        }
        dispatch(getMetricsV2Action(metrics));
    }
}
export interface IGetMetricsv2Action extends IPayloadAction<string, IMetricsV2Result> { 
    type: ActionTypes.GET_METRICS_V2;
}
export const getMetricsV2Action = createPayloadAction<IGetMetricsv2Action>(ActionTypes.GET_METRICS_V2);