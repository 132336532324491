import { Dispatch } from "redux";
import { IAdHocProjectResult, IProject } from "../../models/applicationState";
import AdHocOcrV2Service from "../../services/adHocOcrV2Service";
import { createPayloadAction, IPayloadAction } from "./actionCreators";
import { ActionTypes } from "./actionTypes";


export default interface IAdHocOcrV2Actions {
    loadAdHocOcrProject(requestId: string): Promise<IProject>;
    exportProjectLabelToSource(projectId: string, runGenerateModel: boolean);
}

export function loadAdHocOcrProject(requestId: string): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        const service = new AdHocOcrV2Service();
        const project = await service.load(requestId);
        if (project) {
            dispatch(loadAdHocOcrV2ProjectAction(project));
        }
    }
}

export interface ILoadAdHocOcrV2ProjectAction extends IPayloadAction<string, IAdHocProjectResult> {
    type: ActionTypes.AD_HOC_OCR_V2_LOAD_PROJECT
}

export const loadAdHocOcrV2ProjectAction = createPayloadAction<ILoadAdHocOcrV2ProjectAction>(ActionTypes.AD_HOC_OCR_V2_LOAD_PROJECT);

export function exportProjectLabelToSource(projectId: string, runGenerateModel: boolean) {
    return async (dispatch: Dispatch) => {
        const adHocOcrService = new AdHocOcrV2Service();
        const result = await adHocOcrService.exportProjectLabelToSource(projectId, runGenerateModel);
        return Promise.resolve(result);
    }    
}