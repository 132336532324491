import React from "react";
import styles from './leftPanel.module.css';


interface ILeftPanelProps {
    active: string;
    history: any; // Replace 'any' with the appropriate type for the 'history' prop
}

interface ILeftPanelState {

}

const navItems = [
    { id: 'modelManagement', label: 'Model Management', route: '/model-sdc' },
    { id: 'modelMetrics', label: 'Model Metrics', route: '/model-sdc/modelMetrics' },
    { id: 'autoLabelMetrics', label: 'Auto Labeling Metrics', route: '/model-sdc/autoLabelingMetrics'},
    { id: 'ocrMetrics', label: 'OCR Metrics', route: '/model-sdc/ocrMetrics' },
    { id: 'formRecognizerModels', label: 'Form Recognizer Models', route: '/model-sdc/formRecognizerModels' },
    { id: 'metricsV2', label: 'Metrics', route: '/model-sdc/metricsV2' },
];

export default class LeftPanel extends React.Component<ILeftPanelProps, ILeftPanelState> {

    onNavItemClick = (route: string) => {
        this.props.history.push(route);
    }

    render() {
        return (
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky" style={{paddingTop: '20px'}}>
                    <ul className="nav flex-column">
                        {navItems.map(item => (
                            <li className="nav-item" key={item.id}>
                                <a
                                    className={`nav-link ${styles.navLinkCustom} ${this.props.active === item.id ? styles.navLinkActive : ''}`}
                                    onClick={() => this.onNavItemClick(item.route)}
                                >
                                    {item.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        );
    }
}

