import axios from "axios";
import { IAdHocProjectResult } from "../models/applicationState";


export interface IAdHocOcrV2Service {
    load(requestId: string): Promise<IAdHocProjectResult>;
    exportProjectLabelToSource(projectId: string, runGenerateModel: boolean);
}

export default class AdHocOcrV2Service implements IAdHocOcrV2Service {
    public async load(requestId: string) {
        return new Promise<IAdHocProjectResult>(async (resolve, reject) => {
            try {
                const project = await axios.get<IAdHocProjectResult>(`${process.env.REACT_APP_HACHET_APIV2_URL}GetDocument/${requestId}`);
                if (project) {
                    resolve(project.data);
                }                
            }
            catch(ex) {
                reject(ex);
            }
        })        
    }

    public async exportProjectLabelToSource(projectId: string, runGenerateModel: boolean) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.post(`${process.env.REACT_APP_HACHET_APIV2_URL}ExportDocument`, 
                    {
                        requestId: projectId,
                        generateModel: runGenerateModel
                    });
                if (result && result.data && result.data.successful) {
                    const postUrl = result.data.postUrl;
                    if (postUrl) {
                        this.postToUrl(postUrl, result.data.authorizationHeader, result.data.base64Content);
                        resolve(true);
                    }
                }        
                else {
                    reject(result.data.content);
                }        
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    private base64ToArrayBuffer(base64) {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    private postToUrl(url, token, stringArr) {
        var base64String = btoa(stringArr);
        var byteArrFromStr = this.base64ToArrayBuffer(stringArr);
        axios.post(url, byteArrFromStr, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': token
            }
            }).then(response => {
                if (response.data) {
                    window.location.href = response.data;
                }                
            }).catch(error => {
                console.error(error);
            });
        
    }
}
