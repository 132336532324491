import { IMetricsState } from "../../models/applicationState";
import { AnyAction } from "../actions/actionCreators";
import { ActionTypes } from "../actions/actionTypes";


export const reducer = (state: IMetricsState = null, action: AnyAction): IMetricsState => {
    switch(action.type) {
        case ActionTypes.GET_AUTOLABEL_METRICS: {
            return {
                ...state, 
                autoLabelModelMetrics: action.payload.autoLabelModels,
                totalAutoLabelModelMetrics: action.payload.totalItems
            }
        }
        case ActionTypes.GET_AUTOLABELING_METRICS: {
            return {
                ...state, 
                autoLabelingMetrics: action.payload.autoLabelingModels,
                totalAutoLabelingMetrics: action.payload.totalItems
            }
        }
        case ActionTypes.GET_OCR_METRICS: {
            return {
                ...state, 
                ocrMetrics: action.payload.ocrMetricsModel,
                totalOcrMetrics: action.payload.totalItems
            }
        }
        case ActionTypes.GET_FORM_RECOGNIZER_MODELS: {
            return {
                ...state, 
                formRecognizerModels: action.payload
            }
        }
        case ActionTypes.DELETE_FORM_RECOGNIZER_MODEL: {
            return {
                ...state, 
                formRecognizerModels: state.formRecognizerModels.filter(r => r.modelId !== action.payload) 
            }
        }
        case ActionTypes.GET_AUTOLABEL_METRICS_OPTIONS: {
            return {
                ...state,
                processorOptions: action.payload.processors,
                clientOptions: action.payload.clients
            }
        }
        case ActionTypes.GET_METRICS_V2: {
            return {
                ...state,
                metricsV2List: action.payload.documentRequestMetrics,
                totalMetricsV2: action.payload.totalItems,
                processors: action.payload.processors?.length > 0 ? action.payload.processors : state.processors,
                clientIds: action.payload.clientIds?.length > 0 ? action.payload.clientIds : state.clientIds,
                sources: action.payload.sources?.length > 0 ? action.payload.sources : state.sources,
                totalAutoLabeledDoc: action.payload.totalAutoLabeledDoc,
                totalModelRequest: action.payload.totalModelRequest
            }
        }
        default: {
            return state;
        }
    }
}